import { Component, ViewChild, ViewEncapsulation, signal } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormErrorScrollHelper } from '@nx/core/lib/helpers/form-error-scroll/form-error-scroll.helper';
import { DeceasedErrorCodes } from '@nx/core/lib/interfaces/deceased.interface';
import { LoadingStates } from '@nx/core/lib/interfaces/loading-states.interface';
import { Payment } from '@nx/core/lib/interfaces/payment.interface';
import { AccountService } from 'provider-account/lib/account/account.service';
import { DeceasedService } from 'provider-deceased/lib/deceased.service';
import { ButtonSizes, ButtonStyleTypes } from 'ui-button';
import { DialogTypes } from 'ui-dialog';
import { RouteNames } from '../../app.routes';
import { codeActivationId } from '../../pages/dashboard/payment-feedback/payment-feedback.component';
import { PremiumModalService } from '../../services/premium-modal/premium-modal.helper';

@Component({
	selector: 'dashboard-premium-modal',
	templateUrl: './premium-modal.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class PremiumModalComponent {
	@ViewChild('premiumForm') premiumForm?: NgForm;

	readonly loadingStates = LoadingStates;
	readonly dialogTypes = DialogTypes;
	readonly buttonSizes = ButtonSizes;
	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly deceasedErrorCodes = DeceasedErrorCodes;

	premiumStateSig = this.deceasedService.premiumStateComputed;
	isPremiumModalOpenSig = this.premiumModalService.isModalOpenSig;
	isRedeemPremiumModalOpenSig = signal<boolean>(false);
	premiumCodeControlDataSig = signal<string>('');

	constructor(
		private readonly router: Router,
		private readonly premiumModalService: PremiumModalService,
		private readonly deceasedService: DeceasedService,
		private readonly accountService: AccountService,
	) {}

	closeIntro() {
		this.isPremiumModalOpenSig.set(false);
		this.deceasedService.resetPremiumState();
	}

	openForm() {
		this.isPremiumModalOpenSig.set(false);
		this.isRedeemPremiumModalOpenSig.set(true);
		this.deceasedService.resetPremiumState();
	}

	closeForm() {
		this.isPremiumModalOpenSig.set(false);
		this.isRedeemPremiumModalOpenSig.set(false);
		this.premiumCodeControlDataSig.set('');
		this.premiumForm?.resetForm();
		this.deceasedService.resetPremiumState();
	}

	startPaymentFlow() {
		this.activatePremium();
	}

	submitPremiumForm(event: Event) {
		event.preventDefault();
		if (!this.premiumForm || this.premiumForm.invalid) {
			FormErrorScrollHelper.apply();
			return;
		}
		this.activatePremium(this.premiumCodeControlDataSig());
	}

	private activatePremium(premiumCode?: string) {
		const deceasedId =
			this.accountService.accountStateComputed().data?.deceaseds[0]
				?.guid || '';
		this.deceasedService
			.activatePremium({
				deceasedID: deceasedId,
				...(premiumCode ? { code: premiumCode } : {}),
			})
			.subscribe((response) => {
				this.handleActivateResponse(response, deceasedId, premiumCode);
			});
	}

	private handleActivateResponse(
		response: Payment | undefined,
		deceasedId: string,
		premiumCode?: string,
	) {
		if (!response) {
			return;
		}
		if (response.isPremium && premiumCode) {
			this.accountService.getAccount().subscribe();
			this.router.navigate(
				[
					`/${RouteNames.PAYMENT_FEEDBACK_WITH_ID}`
						.replace(':deceasedId', deceasedId)
						.replace(':paymentId', codeActivationId),
				],
				{
					replaceUrl: true,
				},
			);
			this.closeForm();
		} else if (response.payment.paymentUrl) {
			location.href = response.payment.paymentUrl;
		}
	}
}
