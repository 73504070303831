import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GtmPageViewEvent } from '@nx/core/lib/interfaces/gtm-events.interface';
import { LoadingStates } from '@nx/core/lib/interfaces/loading-states.interface';
import { GoogleTagManagerService } from '@nx/core/lib/services/google-tag-manager/google-tag-manager.service';
import { AccountService } from 'provider-account/lib/account/account.service';
import { filter, map } from 'rxjs';
import { environment } from './../environments/environment';

@Component({
	selector: 'dashboard-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	@ViewChild('scrollContainer') scrollContainer?: ElementRef;

	hasMenu = true;
	hasHeader = true;

	constructor(
		private readonly router: Router,
		private readonly translate: TranslateService,
		private readonly accountService: AccountService,
		private readonly gtmService: GoogleTagManagerService,
	) {
		this.initLanguage();
		this.hideMenuOnConfiguredPages();
		this.scrollTopOnPageChange();
		this.initGoogleTagManager();
		this.registerGtmRouteChanges();
	}

	private initLanguage() {
		this.translate.use(this.translate.defaultLang);
	}

	private hideMenuOnConfiguredPages() {
		this.router.events
			.pipe(
				filter((routeEvent) => routeEvent instanceof ActivationEnd),
				map((routeEvent) => routeEvent as ActivationEnd),
			)
			.subscribe((routeEvent) => {
				this.hasMenu = !routeEvent.snapshot.data['hideMenu'];
				this.hasHeader = !routeEvent.snapshot.data['hideHeader'];
				if (
					this.hasMenu &&
					![LoadingStates.LOADING, LoadingStates.LOADED].includes(
						this.accountService.accountStateComputed().state,
					)
				) {
					this.accountService.getAccount().subscribe();
				}
			});
	}

	private scrollTopOnPageChange() {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				this.scrollContainer?.nativeElement.scrollTo(0, 0);
			});
	}

	private initGoogleTagManager() {
		this.gtmService.init(environment.gtmId);
	}

	private registerGtmRouteChanges() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event): NavigationEnd => event as NavigationEnd),
			)
			.subscribe((event) => {
				this.gtmService.push<GtmPageViewEvent>({
					event: 'page_view',
					page_location: event.url,
				});
			});
	}
}
