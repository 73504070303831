import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
	ApmErrorHandler,
	ApmModule,
	ApmService,
} from '@elastic/apm-rum-angular';
import { NgxTranslateCutModule } from '@gunnaway/ngx-translate-cut';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProviderAccountModule } from 'provider-account/lib/account/account.module';
import { ProviderAuthenticateModule } from 'provider-authenticate/lib/authenticate.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NavigationModule } from './components/navigation/navigation.module';
import { PremiumModalModule } from './components/premium-modal/premium-modal.module';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { TraceContextInterceptor } from './interceptors/trace-context/trace-context.interceptor';
import { AppInitializeService } from './services/app-initialize/app-initialize.service';
import { PremiumModalService } from './services/premium-modal/premium-modal.helper';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(
		http,
		`./assets/i18n/`,
		`.json?t=${Date.now()}`,
	);
}

@Injectable()
export class CustomReuseStrategy extends RouteReuseStrategy {
	retrieve(): null {
		return null;
	}

	shouldAttach(): boolean {
		return false;
	}

	shouldDetach(): boolean {
		return false;
	}

	shouldReuseRoute(): boolean {
		return false;
	}

	store(): void {
		return;
	}
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		ApmModule,
		RouterModule.forRoot(appRoutes, {
			anchorScrolling: 'enabled',
			bindToComponentInputs: true,
			initialNavigation: 'enabledBlocking',
			scrollPositionRestoration: 'enabled',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
			defaultLanguage: 'nl',
		}),
		NgxTranslateCutModule,
		NavigationModule,
		ProviderAccountModule,
		ProviderAuthenticateModule,
		PremiumModalModule,
	],
	providers: [
		ApmService,
		AppInitializeService,
		PremiumModalService,
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		},
		{
			provide: RouteReuseStrategy,
			useClass: CustomReuseStrategy,
		},
		{
			provide: 'apiBaseUrl',
			useValue: environment.apiBaseUrl,
		},
		{
			provide: 'apiSource',
			useValue: environment.source,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: TraceContextInterceptor,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	/* istanbul ignore next */
	constructor(private readonly appInitializeService: AppInitializeService) {
		this.appInitializeService.init();
	}
}
